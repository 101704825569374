/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Hero from "../containers/home-particle/hero";
import Story from "../containers/home-particle/story";
import Access from "../containers/contact/contact-info";
import About from "../containers/company/about";
import Project from "../containers/home-particle/project";

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <Story />
    <About />
    <Project />
    <Access />
  </Layout>
);
export default IndexPage;
